import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./WebTechStacksMobile.scss"
import * as styles from "./WebTechStacksMobile.module.scss"

const WebTechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Frontend",
      tech: [
        {
          name: "React.js",
          icon: "https://invozone-backend.s3.amazonaws.com/react_js_ec0bca327d.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_accf40208e.svg",
        },
        {
          name: "HTML",
          icon: "https://invozone-backend.s3.amazonaws.com/html_b98b41dd3a.svg",
        },
        {
          name: "CSS",
          icon: "https://invozone-backend.s3.amazonaws.com/css_23fa4ecec3.svg",
        },
        {
          name: "Gatsby",
          icon: "https://invozone-backend.s3.amazonaws.com/gatsby_405ad4bb09.svg",
        },
        {
          name: "Vue",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_fc7995e57d.svg",
        },
        {
          name: "Ember.js",
          icon: "https://invozone-backend.s3.amazonaws.com/ember_js_ee76037a94.svg",
        },
        {
          name: "Svelte",
          icon: "https://invozone-backend.s3.amazonaws.com/svelte_650bf821f2.svg",
        },
        {
          name: "Backbone.js",
          icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
        },

        {
          name: "Polymer",
          icon: "https://invozone-backend.s3.amazonaws.com/polymer_d383b57734.svg",
        },
        {
          name: "Aurelia",
          icon: "https://invozone-backend.s3.amazonaws.com/aurelia_a1de211654.svg",
        },
        {
          name: "Meteor.js",
          icon: "https://invozone-backend.s3.amazonaws.com/meteor_js_eaac2a1b7e.svg",
        },
        {
          name: "Next.js",
          icon: "https://invozone-backend.s3.amazonaws.com/next_js_e5339eec59.svg",
        },
        {
          name: "Semantic-UI",
          icon: "https://invozone-backend.s3.amazonaws.com/semantic_ui_21b02146bb.svg",
        },
        {
          name: "Nuxt",
          icon: "https://invozone-backend.s3.amazonaws.com/nuxt_7c390efd18.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "Node.js",
          icon: "https://invozone-backend.s3.amazonaws.com/node_js_73bf656e99.svg",
        },
        {
          name: "Elixir",
          icon: "https://invozone-backend.s3.amazonaws.com/elixir_9a4aafe1ac.svg",
        },
        {
          name: ".Net",
          icon: "https://invozone-backend.s3.amazonaws.com/dot_net_core_60b6468ed9.svg",
        },
        {
          name: "Python",
          icon: "https://invozone-backend.s3.amazonaws.com/python_2c94b7b2ba.svg",
        },
        {
          name: "Ruby on Rails",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_7ebc4c7a74.svg",
        },
        {
          name: "Java",
          icon: "https://invozone-backend.s3.amazonaws.com/java_ea77870d12.svg",
        },
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.amazonaws.com/php_5b4ff59f57.svg",
        },
        {
          name: "Django",
          icon: "https://invozone-backend.s3.amazonaws.com/django_151bbe5125.svg",
        },
        {
          name: "Go",
          icon: "https://invozone-backend.s3.amazonaws.com/golang_af33d97d05.svg",
        },
        {
          name: "C#",
          icon: "https://invozone-backend.s3.amazonaws.com/c_sharp_9f096f5266.svg",
        },
        {
          name: "Kotlin",
          icon: "https://invozone-backend.s3.amazonaws.com/kotlin_13d275b8c5.svg",
        },
        {
          name: "Scala",
          icon: "https://invozone-backend.s3.amazonaws.com/scala_461e2efb2a.svg",
        },
        {
          name: "Rust",
          icon: "https://invozone-backend.s3.amazonaws.com/rust_8dcee0b849.svg",
        },
      ],
    },
    {
      title: "Libraries",
      tech: [
        {
          name: "jQuery",
          icon: "https://invozone-backend.s3.amazonaws.com/jquery_ec723c935f.svg",
        },
        {
          name: "React",
          icon: "https://invozone-backend.s3.amazonaws.com/react_9aef019c87.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_3e5d058b96.svg",
        },
        {
          name: "Vue.js",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_js_1fc7d4170d.svg",
        },
        {
          name: "Vue.x",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_x_02ab70bb86.svg",
        },
        {
          name: "Rxjs",
          icon: "https://invozone-backend.s3.amazonaws.com/rx_js_c25c3dc4c7.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_bb20df2e8a.svg",
        },
        {
          name: "Backbone.js",
          icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
        },
        {
          name: "Ember.js",
          icon: "https://invozone-backend.s3.amazonaws.com/ember_js_c658b7b960.svg",
        },
        {
          name: "D3.js",
          icon: "https://invozone-backend.s3.amazonaws.com/d3_js_9a1ec60030.svg",
        },
        {
          name: "Redux",
          icon: "https://invozone-backend.s3.amazonaws.com/redux_df2c6c2f02.svg",
        },
        {
          name: "Lodash",
          icon: "https://invozone-backend.s3.amazonaws.com/lodash_a92ed646c4.svg",
        },
        {
          name: "Styled Component",
          icon: "https://invozone-backend.s3.amazonaws.com/styled_component_4fab00ca49.svg",
        },
        {
          name: "Emotion",
          icon: "https://invozone-backend.s3.amazonaws.com/emotion_2cc14a0dc9.svg",
        },
        {
          name: "Graph QL",
          icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
        },
        {
          name: "Ant Design",
          icon: "https://invozone-backend.s3.amazonaws.com/ant_design_45399a05bc.svg",
        },
        {
          name: "Inertia.js",
          icon: "https://invozone-backend.s3.amazonaws.com/inertia_b5a7d1b2d0.svg",
        },
        {
          name: "Mobx",
          icon: "https://invozone-backend.s3.amazonaws.com/mobx_3aab383efb.svg",
        },
        {
          name: "Web3",
          icon: "https://invozone-backend.s3.amazonaws.com/web3_js_5343b9f853.svg",
        },
        {
          name: "WebGL",
          icon: "https://invozone-backend.s3.amazonaws.com/Web_GL_2e8ab586a5.svg",
        },
        {
          name: "Core UI",
          icon: "https://invozone-backend.s3.amazonaws.com/core_ui_21f2c32bb7.svg",
        },
        {
          name: "Tailwind",
          icon: "https://invozone-backend.s3.amazonaws.com/tailwind_6e2075c057.svg",
        },
        {
          name: "Material UI",
          icon: "https://invozone-backend.s3.amazonaws.com/material_ui_ac94034f00.svg",
        },
        {
          name: "Flow.js",
          icon: "https://invozone-backend.s3.amazonaws.com/flow_js_69264dac66.svg",
        },
      ],
    },
    {
      title: "Databases",
      tech: [
        {
          name: "MySQL",
          icon: "https://invozone-backend.s3.amazonaws.com/mysql_b1008b93a5.svg",
        },
        {
          name: "Couch DB",
          icon: "https://invozone-backend.s3.amazonaws.com/couchdb_24e9dcaed8.svg",
        },
        {
          name: "Indexed DB",
          icon: "https://invozone-backend.s3.amazonaws.com/indexed_db_a57ee0afa4.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.amazonaws.com/postgresql_b9e4bb71b7.svg",
        },
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mongodb_7362fb906b.svg",
        },
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.amazonaws.com/sqlite_ac79b80b2e.svg",
        },
        {
          name: "MS SQL Server",
          icon: "https://invozone-backend.s3.amazonaws.com/microsoft_sql_server_1128f46ae9.svg",
        },
        {
          name: "Firebase",
          icon: "https://invozone-backend.s3.amazonaws.com/firebase_6cd830fadd.svg",
        },
        {
          name: "Swift",
          icon: "https://invozone-backend.s3.amazonaws.com/swift_f17a2f9dd0.svg",
        },
        {
          name: "Redis",
          icon: "https://invozone-backend.s3.amazonaws.com/redis_02864b6029.svg",
        },
        {
          name: "DynamoDB",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_dynamodb_0ed8beafce.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="webAppTechStacksMobile">
      <div className={styles.WebTechMobiles}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="webCustomCard" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`customAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default WebTechStacksMobile
