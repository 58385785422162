// extracted by mini-css-extract-plugin
export var cardBg = "WebTechStacks-module--cardBg--a2124";
export var cards = "WebTechStacks-module--cards--151a2";
export var cardsContent = "WebTechStacks-module--cardsContent--4a930";
export var description = "WebTechStacks-module--description--6e224";
export var heading = "WebTechStacks-module--heading--c9386";
export var iconssCard = "WebTechStacks-module--iconssCard--b4376";
export var nav = "WebTechStacks-module--nav--e9222";
export var navItem = "WebTechStacks-module--nav-item--b76e6";
export var navbarBlock = "WebTechStacks-module--navbarBlock--2889f";
export var tabData = "WebTechStacks-module--tabData--4f7d8";
export var tech = "WebTechStacks-module--tech--1535f";
export var techIcon = "WebTechStacks-module--techIcon--92c71";
export var techImg = "WebTechStacks-module--techImg--24afe";
export var technologyIcon = "WebTechStacks-module--technologyIcon--ea244";
export var webTechapplication = "WebTechStacks-module--webTechapplication--92fe1";