// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--e3280";
export var bannerBtn = "Banner-module--bannerBtn--66b93";
export var bannerHeading = "Banner-module--bannerHeading--a349a";
export var bannerRight = "Banner-module--bannerRight--84092";
export var bannerWebApplication = "Banner-module--bannerWebApplication--578fe";
export var btn_white2_border = "Banner-module--btn_white2_border--03ebb";
export var head = "Banner-module--head--b751d";
export var heading = "Banner-module--heading--83d56";
export var rowCentered = "Banner-module--rowCentered--ff110";
export var subTitle = "Banner-module--subTitle--b98f6";
export var subTitle2 = "Banner-module--subTitle2--b434b";