// extracted by mini-css-extract-plugin
export var WebTechMobiles = "WebTechStacksMobile-module--WebTechMobiles--9fdf9";
export var cardBg = "WebTechStacksMobile-module--cardBg--70792";
export var cards = "WebTechStacksMobile-module--cards--18fb2";
export var cardsContent = "WebTechStacksMobile-module--cardsContent--d0ef3";
export var description = "WebTechStacksMobile-module--description--37177";
export var heading = "WebTechStacksMobile-module--heading--d762b";
export var iconssCard = "WebTechStacksMobile-module--iconssCard--d5b48";
export var nav = "WebTechStacksMobile-module--nav--f6339";
export var navItem = "WebTechStacksMobile-module--nav-item--77d79";
export var navbarBlock = "WebTechStacksMobile-module--navbarBlock--68240";
export var tabData = "WebTechStacksMobile-module--tabData--221de";
export var tech = "WebTechStacksMobile-module--tech--891e0";
export var techIcon = "WebTechStacksMobile-module--techIcon--27210";
export var techImg = "WebTechStacksMobile-module--techImg--5f926";
export var technologyIcon = "WebTechStacksMobile-module--technologyIcon--d6875";