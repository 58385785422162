import React from "react"
import * as styles from "./Experience.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Experience = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.webExperience}>
        <Container>
          <Row
            className={` d-flex justify-content-center ${styles.cardWrapper}`}
          >
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col
                  lg={3}
                  md={6}
                  xs={12}
                  key={i}
                  className={styles.coloumnExperience}
                >
                  <div
                    className={
                      i === 0
                        ? styles.card1
                        : i === 1
                        ? styles.card2
                        : i === 3
                        ? styles.card4
                        : styles.card
                    }
                  >
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "auto",
                        background: "white",
                        padding: "30px",
                        borderRadius: "90px",
                        border: "1px solid #2176FF",
                      }}
                    ></lottie-player>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Experience
