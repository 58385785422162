import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/web-application-development-servics/Banner"
import Features from "../../components/cms/Features"
import FeaturesMobile from "../../components/cms/FeaturesMobile"
import Opportunity from "../../components/web-application/Opportunity"
import AngularIndustries from "../../components/angular-developers/AngularIndustries"
import WebTechStacks from "../../components/web-application-development-servics/WebTechStacks"
import WebTechStacksMobile from "../../components/web-application-development-servics/WebTechStacksMobile"
import Protect from "../../components/web-application-development-servics/Protect"
import Process from "../../components/softwere-development/Process"
import Experience from "../../components/web-application-development-servics/Experience"
import ProtectMobile from "../../components/web-application-development-servics/ProtectMobile"
import Extend from "../../components/web-application-development-servics/Extend"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const webapplication = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const dynamic = data?.strapiPage?.sections[1]
  const standards = data?.strapiPage?.sections[2]
  const industries = data?.strapiPage?.sections[3]
  const techWeb = data?.strapiPage?.sections[4]
  const protect = data?.strapiPage?.sections[5]
  const process = data?.strapiPage?.sections[6]
  const experience = data?.strapiPage?.sections[7]
  const extend = data?.strapiPage?.sections[8]
  const smoothest = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[10]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      {!isMobile && !isTablet ? (
        <Features strapiData={dynamic} sliderWebApplication={true} />
      ) : (
        <FeaturesMobile strapiData={dynamic} />
      )}
      <Opportunity
        strapiData={standards}
        webOpportunity={true}
        cmsopportunity={true}
      />
      <AngularIndustries strapiData={industries} webappIndustries={true} />
      {!isMobile && !isTablet ? (
        <WebTechStacks strapiData={techWeb} />
      ) : (
        <WebTechStacksMobile strapiData={techWeb} />
      )}
      {!isMobile && !isTablet ? (
        <Protect strapiData={protect} />
      ) : (
        <ProtectMobile strapiData={protect} />
      )}

      <Process strapiData={process} webSteps={true} />
      <Experience strapiData={experience} />
      <Extend strapiData={extend} />
      <Opportunity strapiData={smoothest} webOpportunity={true} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "web-application" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default webapplication
