// extracted by mini-css-extract-plugin
export var btn = "Extend-module--btn--8265b";
export var cards = "Extend-module--cards--96f95";
export var customBtn = "Extend-module--customBtn--70064";
export var description = "Extend-module--description--f54e5";
export var extendWeb = "Extend-module--extendWeb--61169";
export var hireImg = "Extend-module--hireImg--97c5b";
export var hireTechnical = "Extend-module--hireTechnical--57da8";
export var subTitle = "Extend-module--subTitle--c8885";
export var talent = "Extend-module--talent--820e3";
export var teamBtn = "Extend-module--teamBtn--8d6f1";
export var text = "Extend-module--text--4eae0";
export var tick = "Extend-module--tick--c39d2";