// extracted by mini-css-extract-plugin
export var SliderWidth = "Features-module--SliderWidth--6c922";
export var arrowImg = "Features-module--arrowImg--3b78b";
export var card = "Features-module--card--597a3";
export var cardImg = "Features-module--cardImg--912a7";
export var cardMian = "Features-module--cardMian--3aab1";
export var cardWrapper = "Features-module--cardWrapper--d5db5";
export var cards = "Features-module--cards--2c717";
export var cmsWebIconCOntainer = "Features-module--cmsWebIconCOntainer--0f44e";
export var cmsWebIconCOntainerLeft = "Features-module--cmsWebIconCOntainerLeft--872d0";
export var description = "Features-module--description--3da7d";
export var heading = "Features-module--heading--b5c5e";
export var headingPremium = "Features-module--headingPremium--0561d";
export var mainWorkFeaturesWebCms = "Features-module--mainWorkFeaturesWebCms--5a750";
export var portfolioArrowIcon = "Features-module--portfolioArrowIcon--cebe7";
export var portfolioArrowIconCover = "Features-module--portfolioArrowIconCover--a366c";
export var portfolioArrowRightIconCover = "Features-module--portfolioArrowRightIconCover--b2130";
export var premiumImg = "Features-module--premiumImg--4f4f1";
export var providingImg = "Features-module--providingImg--6bae6";
export var sliderWebApplicationservices = "Features-module--sliderWebApplicationservices--c217c";
export var teamButton = "Features-module--teamButton--3ac48";
export var trailBg = "Features-module--trailBg--bfec5";